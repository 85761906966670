.footer {
    background: rgba(229, 186, 180, 0.57);
    border: 5px solid #FFF;
    position: relative;

}

.footer-container {
    position: relative;
}

.footer-column {
    padding: 20px;
    /* Añade padding a las columnas para asegurarte de que el contenido no se superponga */
    position: relative;
    /* Asegura que los elementos absolutamente posicionados estén relativos a las columnas */
}

.footer-icon {
    color: #7F6F62;
    width: 24px;
    height: 24px;
}

.footer h5 {
    color: #7F6F62;
    font-weight: bold;
    margin-bottom: 15px;
    text-transform: uppercase;
}

.footer p,
.footer a {
    color: #7F6F62;
    font-size: 14px;
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}

.line-container {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.vertical-line {
    width: 5px;
    height: 100%;
    background-color: #FFF;
    margin: 0 8px;
}

.image-wood {
    height: 100%;
    margin: 0 3px;
}

.line-container.left {
    left: 0;
}

.line-container.right {
    right: 0;
}

.frame-wood {
    display: none;
}

.company-phone {
    display: none;
}

@media (max-width: 1000px) {
    .footer {
        background: rgba(229, 186, 180, 0.57);
    }

    .frame-wood {
        display: block;
        /* Asegura que el contenedor se muestre */
    }

    .company-desk {
        display: none;
    }

    .company-phone {
        display: block;
    }

    .image-frameWood {
        width: 100%;
        /* Ajusta el ancho según sea necesario */
        height: auto;
        /* Mantiene la proporción de la imagen */
    }

    .line-container.left {
        display: none;
    }

    .line-container.right {
        display: none;
    }
}