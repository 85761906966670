@import url('https://fonts.googleapis.com/css2?family=Playball&display=swap');

html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: url('../images/background.png') lightgray 50% / cover no-repeat;
    /* Imagen de fondo para toda la página */
}

.container-fluid {
    padding: 0 !important;
    margin: 0 !important;
}

.banner {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.cover-image {
    width: 100%;
    height: auto;
    display: block;
}

.welcome-container {
    position: absolute;
    top: 360px;
    left: 20px;
    display: flex;
    align-items: center;
    width: calc(100% - 40px);
    /* Ajustar el ancho para que el contenido no se salga de la pantalla */
}

.welcome-text {
    color: #FFF;
    font-family: Inter, sans-serif;
    font-size: 14vw;
    font-weight: 600;
    line-height: 1.2;
    mix-blend-mode: overlay;
    flex: 1;
    /* Ocupa todo el espacio disponible */
}

.redirect-image-container {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 386px;
    /* Alineado verticalmente con el texto Welcome */
    right: 10px;
    z-index: 1000;
    /* Asegura que el botón esté por encima del contenido */
    text-align: center;
}

.redirect-image {
    width: 155px;
    height: 137px;
    object-fit: contain;
}

.find-us-text {
    color: #AC443B;
    font-family: 'Playball', cursive;
    /* Ajusta la familia de fuentes */
    font-size: 38px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
    top: -38px;
    /* Espacio negativo para colocar el texto sobre la imagen */
    margin-bottom: 0;
    /* Espacio inferior cero */
}

.blue-container-image {
    width: 100%;
    height: auto;
    margin: 60px 0;
    /* Ajustar según sea necesario */
    display: block;
}

.frame-wood-image {
    width: 100%;
    height: auto;
    margin-top: 30px;
    /* Ajustar según sea necesario */
    display: block;
}

.frame-wood-image-phone {
    display: none;
}

.blue-container-image-phone {
    display: none;
}
.custom-button {
    color: #7F6F62; /* Cambiar color de la letra */
    background-color: #E7EDF4 !important; /* Color de fondo */
    border-color: white !important; /* Color del borde */
    text-transform: uppercase; /* Transformación del texto */
  }
  
.custom-button:hover {
    color: #C48989; /* Cambiar color de la letra al pasar el cursor */
  }
@media (max-width: 768px) {
    .banner {
        height: 100vh;
    }

    .cover-image {
        width: 100%;
        height: 100vh;
        object-fit: cover;
    }

    .welcome-container {
        top: 70%;
        left: 10px;
        width: calc(100% - 20px);
        /* Ajustar el ancho para que el contenido no se salga de la pantalla */
        flex-direction: column;
        align-items: flex-start;
    }

    .welcome-text {
        font-size: 18vw;
        margin-bottom: 10px;
        /* Espacio entre el texto y el botón en móviles */
    }

    .redirect-image-container {
        top: auto;
        bottom: 20px;
        /* Ajustar para móviles */
        right: 10px;
    }

    .custom-background {
        display: flex;
        padding: 0;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 100%;
        height: 660px;
        /* Altura fija de 660px */
    }

    .text-column,
    .image-column {
        text-align: center;
        padding: 0;
    }

    .text-column {
        width: 100%;
        height: auto;
        margin-bottom: 20px;
        /* Espacio entre el texto y la imagen */
    }

    .text-column h2 {
        color: #C48989;
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: 55px;
        /* 85.938% */
    }

    .secondary-image {
        width: 80%;
        height: auto;
        margin-bottom: 20px;
        /* Espacio entre el texto y la imagen */
    }


}

@media (max-width: 480px) {
    .welcome-container {
        top: 70%;
        left: 10px;
        width: calc(100% - 20px);
        /* Ajustar el ancho para que el contenido no se salga de la pantalla */

    }

    .welcome-text {
        font-size: 20vw;
        /* Espacio entre el texto y el botón en móviles */
    }

    .redirect-image-container {
        top: 460px;
        bottom: 0px;
        /* Ajustar para móviles */
        right: 5px;

    }


    .redirect-image {
        width: 80px;
        height: 90px;
    }

    .find-us-text {
        font-size: 28px;
        margin-top: 10px;
    }

    .custom-background {
        padding: 0px 20px;
        display: flex;
        width: 430px;
        height: 923px;
        flex-direction: column;

        flex-shrink: 0;
        /* Ajustar la altura para pantallas pequeñas */
    }

    .blue-container-image {
        display: none;
    }

    .blue-container-image-phone {
        display: block;
        width: 100%;
        height: auto;
        /* Ajustar según sea necesario */
    }

    .text-column {
        display: flex;
        padding: 0px 34px 0px 36px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
    }

 
    .text-column h2 {
        color: #C48989;
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 55px;
        /* 171.875% */
    }

    .secondary-image {
        width: 100%;
        height: auto;

    }

    .corn-image {
        width: 100%;
        height: auto;
        margin: 20px 0;
        /* Espacio entre el texto y la imagen */
    }

    .frame-wood-image {
        display: none;

    }

    .frame-wood-image-phone {
        width: 100%;
        height: auto;
        margin-top: 30px;
        /* Ajustar según sea necesario */
        display: block;
    }
}