.custom-navbar {
  background-color: #fff !important;
  height: 67px;
  padding: 0;
}

.custom-nav {
  display: flex;
  align-items: center;
  gap: 70px;
  margin-left: 120px;
}

.custom-nav .nav-link {
  color: #7F6F62 !important;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.custom-logo-container {
  display: flex;
  align-items: center;
  height: 67px;
}

.custom-logo {
  width: 168px;
  height: auto;
}

@media (max-width: 768px) {
  .custom-navbar {
    padding: 0 20px;
    background-color: #fff !important;
    
  }

  .custom-nav {
    flex-direction: column;
    align-items: flex-end;
    padding: 0;
    gap: 10px;
    margin-right: 30px;
  }

  .custom-logo-container {
    order: -1;
    margin-bottom: 0px; /* Espacio entre el logo y los links en pantallas pequeñas */
  }

  .custom-logo {
    width: 168px;
    height: auto;
  }
  .navbar-collapse {
    background-color: #fff !important;
  }
}
